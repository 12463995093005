
import React from "react";
import New7 from "../images/new7.jpg";
import Amrutha from "../images/Amrutha2.jpg";
import Guru from "../images/Guru.jpg";
import Vinutha from "../images/Vinutha.jpg";
import Santosh from "../images/Santosh2.jpg";
import New5 from '../images/new5.jpg';
import Prapthi from "../images/Prapthi2.jpg";
import Amrutha1 from "../images/Amrutha2.jpg";
import Chandan from "../images/Chandan.jpg";
import Chandan1 from "../images/Chandana2.jpg";
import Doresh from "../images/Doresh.jpg";
import Gowtham from "../images/Gowtham.jpg";
import Guru1 from "../images/Guru2.jpg";
import Kavita from "../images/Kavita.jpg";
import New4 from "../images/new4.jpg";
import New6 from "../images/new6.jpg";
import New8 from "../images/new8.jpg";
import New9 from "../images/new9.jpg";
import Pavan from "../images/Pavan.jpg";
import Prapthi1 from "../images/Prapthi.jpg";
import Rajeshwari from "../images/Rajeshwari.jpg";
import Raksha from "../images/Raksha.jpg";
import Rey from "../images/Reyansh.jpg";
import Rohit from "../images/Rohith.jpg";
import Saloni from "../images/Saloni.jpg";
import Saloni1 from "../images/Saloni2.jpg";
import Sandeep from "../images/Sandeep.jpg";
import Set from "../images/Set.jpg";
import Sheker from "../images/Shaker.jpg";
import Shruthi from "../images/Shruthi.jpg";
import Swetha from "../images/Shwetha.jpg";
import Sowmya from "../images/Sowmya.jpg";
import Srinivas from "../images/Srinivas.jpg";
import Srividya from "../images/Srividya.jpg";
import Swastik from "../images/Swastik.jpg";
import Vara from "../images/Varalakshmi.jpg";
import Venkat from "../images/Venkat.jpg";
import Vidya from "../images/Vidya.jpg";
import New1 from "../images/new1.jpg";

export default function Gallery() {
  return (
    <div>
      <div
        className=" text-black mx-10 md:text-4xl mt-10 mr-20 2xl:text-4xl font-semibold mb-5 text-center"
        style={{ fontFamily: "poppins" }}
      >
        Gallery
      </div>

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mx-10 mb-5">
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Vinutha} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Santosh} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Guru} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New5} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Chandan} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Chandan1} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Doresh} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Gowtham} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Guru1} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Kavita} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New4} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New6} alt="" />
        </div>
      </div>
      {/* ............................................................................................................. */}

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mx-10 mb-5">
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New8} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New9} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Pavan} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Prapthi1} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Rajeshwari} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Raksha} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Rey} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Rohit} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Saloni} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Saloni1} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Sandeep} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Set} alt="" />
        </div>
      </div>

      {/* ................................................................................................................. */}

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mx-10 mb-20">
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Sheker} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Shruthi} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Swetha} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Sowmya} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Srinivas} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Swastik} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Srividya} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Vara} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Venkat} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={Amrutha} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New1} alt="" />
        </div>
        <div>
          <img class="h-auto max-w-full rounded-lg" src={New7} alt="" />
        </div>
      </div>
    </div>
  );
}
