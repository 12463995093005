import React, { useState, useEffect } from "react";
import Gold from "../images/Young.png";
import { motion } from "framer-motion";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropabout, setDropabout] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  return (
    <div class="sticky top-0 z-50 " style={{ fontFamily: "poppins" }}>
      <nav class="hidden md:block bg-gray-700 h-20 pr-10 ">
        <div class="container flex flex-wrap items-center justify-between mx-auto">
          <a href="/" class="flex items-center">
            <img src={Gold} class="h-14 ml-10" alt="Flowbite Logo" />
          </a>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul class="flex flex-col pt-7 p-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0">
              <li>
                <a
                  href="/"
                  class="block py-2 pl-3 pr-4 text-xl text-white bg-white rounded md:bg-transparent md:text-white md:p-0 md:hover:text-[#DAC56F]"
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/aboutus"
                  class="block py-2 text-xl pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#DAC56F] md:p-0 "
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/whatwedo"
                  class="block py-2 pl-3 pr-4 text-xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#DAC56F] md:p-0 "
                >
                  What We Do
                </a>
              </li>
              <li>
                <a
                  href="/gallery"
                  class="block py-2 pl-3 pr-4 text-xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#DAC56F] md:p-0 "
                >
                  Our Work
                </a>
              </li>

              <li>
                <a
                  href="/contactus"
                  class="block py-2 pl-3 pr-4 text-xl text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#DAC56F] md:p-0 "
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* --------------------------------------mobile------------------------------------------------- */}

      <div className="md:hidden bg-gray-700">
        <div class="flex items-center justify-between">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class=" p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-white" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          <img class="h-16" src={Gold} alt="" width="auto" />
        </div>
        <div class="lg:hidden top-0 left-0">
          {isMenuOpen && (
            <SidebarWrap
              key="first"
              initial={{ x: -250, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -280, opacity: 1 }}
              transition={{
                duration: 0.36,
                type: "tween",
                ease: "easeInOut",
              }}
            >
              <div class="absolute w-full ">
                <hr />
                <MenuWrap>
                  <nav>
                    <ul
                      class="space-y-2   text-left   text-[14px] text-indigo-800"
                      style={{ fontFamily: "Open Sans" }}
                    >
                      <li className="nav-item pl-2 font-bold ">
                        <a
                          activeClassName="selected"
                          exact
                          href="/"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item pl-2 font-bold">
                        <a
                          href="/aboutus"
                          onClick={() => setIsMenuOpen(false)}
                          activeClassName="selected"
                        >
                          About Us
                        </a>
                      </li>
                      <li className="nav-item pl-2 font-bold">
                        <a
                          href="/whatwedo"
                          onClick={() => setIsMenuOpen(false)}
                          activeClassName="selected"
                        >
                          What We Do
                        </a>
                      </li>
                      <li className="nav-item pl-2 font-bold">
                        <a
                          href="/gallery"
                          onClick={() => setIsMenuOpen(false)}
                          activeClassName="selected"
                        >
                          Our Work
                        </a>
                      </li>
                      <li className="nav-item pl-2 font-bold">
                        <a
                          href="/contactus"
                          onClick={() => setIsMenuOpen(false)}
                          activeClassName="selected"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </MenuWrap>
              </div>
            </SidebarWrap>
          )}
        </div>
      </div>
    </div>
  );
}
const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }
  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;
const BackdropWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;
const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;
const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;
    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }
  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }
  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;
        &:hover {
          background-color: #408080;
          color: white;
        }
      }
      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;
      svg {
        font-size: 1.3rem;
      }
      .selected {
        background-color: #88448a;
        color: white;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
