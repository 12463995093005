import React from "react"
import Vidya from "../images/Vidya.jpg"
import Prapthi from "../images/Prapthi.jpg"
import "../../src/App.css"
import Red from "../../src/images/white.png"
import Blue from "../../src/images/blueb.png"
import Green from "../../src/images/greenb.png"
import Yellow from "../../src/images/yellow.png"
import White from "../../src/images/blue.png"

export default function Landing() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <section>
      <div>
        <div class="balloons hidden md:block">
          <img src={Blue} />
          <img src={Green} />
          <img src={Yellow} />
          <img src={White} />
          <img src={Red} />
          <img src={Blue} />
          <img src={Green} />
          <img src={Yellow} />
          <img src={White} />
          <img src={Red} />
          <img src={Blue} />
          <img src={Green} />
          <img src={Yellow} />
          <img src={White} />
          <img src={Red} />
        </div>
      </div>
      <div>
        <img class='w-full h-80 md:h-[800px]' src={Vidya}></img>
      </div>
      <section class="md:py-16 bg-no-repeat bg-center bg-cover bg-fixed overflow-hidden mx-2 md:mx-20"style={{fontFamily:'poppins'}}>
        <div class="md:container md:px-4 md:flex">
          <div class="md:px-12 md:pt-12 md:pb-9 md:max-w-xl bg-opacity-80 rounded-4xl">
            <h2 class="text-2xl md:text-7xl text-black font-bold font-heading tracking-px-n mt-2 md:mb-7">
              Welcome To Dreams Events & Services!
            </h2>
            <p class="md:mb-5 text-lg text-gray-900 font-medium leading-normal">
              <h3 class="text-2xl mt-2"> Dream, Design, Deliver.</h3>
 
              <p class="md:mt-4 mt-2">
                We take the responsibility of making your dream a reality!
              </p>
            </p>
          </div>
          <div>
            <img class="w-96 h-80 md:w-[1000px] md:h-[500px] rounded-xl" src={Prapthi}></img>
          </div>
        </div>
        <div
          class="text-black text-2xl md:mx-20 mt-5 mb-5 mx-1"
          style={{ fontFamily: "poppins" }}
        >
          <h1 class='text-black text-2xl'>About Us</h1>
          <p class="text-base mt-5">
            We are Corporate Event Planners and one of the Best Event Management
            Company in Bangalore with our services available PAN India. Our
            forte lies in delivering cutting edge, innovative and professional
            event management services. We are a bunch of fun loving, energetic
            and passionate people working towards the common goal of providing
            exceptional services. From inspirational ideas to stunning
            productions, strong themes and creative programs, we do everything
            to create memorable experiences and branding opportunities that
            exceed customer expectations. We take pride in presenting ourselves
            as one of the best in the industry. We offer a wide range of event
            management services. Be it a Virtual conference, Award ceremony,
            Product launch, Family event or Sports event, we have you covered.
            We are a one stop solution to all your corporate situations.
          </p>
        </div>
      </section>
    </section>
  )
}
