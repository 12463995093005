import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./components/index";
import Aboutus from "./components/about";
import Appwrap from "./components/global/appwrap";
import Whatwedo from './components/whatwedo'
import Gallery from './components/gallery'
import Contactus from './components/contactus'


export default function App() {
  return (
    <div>
      <Appwrap>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/whatwedo" element={<Whatwedo />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/contactus" element={<Contactus />} />


          

        </Routes>
      </Appwrap>
    </div>
  );
}
