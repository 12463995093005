import React from 'react'
import Col from './col'
import Footer from './footer'
import Navbar from './navbar'
import Landing from './landing'
import Why from './why'
import Testimonials from './testinomials'

export default function App() {
  return (
    <div>
      
      <Landing/>
      <Col/>
      <Why/>
      {/* <Testimonials/> */}
      
    </div>
  )
}
