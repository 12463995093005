import React from "react"

export default function Col() {
  return (
    <div>
      <section
        class="md:py-24 bg-blueGray-50 overflow-hidde bg-gradient-to-br  from-[#0F2027] via-[#24243e] to-[#2C5364]"
        style={{ fontFamily: "poppins" }}
      >
        <div class="container px-4 mx-auto h-auto">
          <h2 class="mb-4 pt-4 text-3xl md:text-5xl text-center font-heading font-heading tracking-px-n leading-none text-[#FFEFBA]">
            One Events Platform. Endless Brand Potential.
          </h2>
          <p class="md:mb-16 mb-8 text-lg text-[#FFEFBA] text-center font-medium leading-normal md:max-w-lg mx-auto">
            Built with you in mind, our end-to-end solution and technology
            enabled services support every step of the event life cycle.
          </p>
          <div class="md:max-w-6xl mx-auto">
            <div class="flex flex-wrap -m-3.5 mb-10">
              <div class="w-full md:w-1/3 p-3.5">
                <a href="/gallery">
                  <div class="relative p-6 h-full hover:text-white hover:bg-gradient-to-br from-[#333333] to-[#e1eec3] border hover:border-amber-500 rounded-xl">
                    <img
                      class="absolute left-0 top-0"
                      src="flaro-assets/images/career/gradient.svg"
                      alt=""
                    />
                    <div class="relative z-10 flex flex-col justify-between h-full">
                      <div class="mb-10 flex-1">
                        <h3 class="mb-2 text-xl font-semibold font-heading leading-snug text-[#FFEFBA]">
                          Birthday Parties
                        </h3>
                        <p class="text-sm text-gray-500 font-medium">
                          <hr class="h-1 my-8 bg-gradient-to-r from-white border-0" />
                          <p class="text-xl text-[#FFEFBA]">
                            Kids birthday party planners & birthday party
                            organisers in Bangalore
                          </p>
                        </p>
                        <button class="bg-orange-500 hover:bg-red-700 text-white font-bold py-2 px-14 ml-12 mt-10 rounded-full">
                          Click for more
                        </button>
                      </div>
                      <div class="flex-1">
                        <img
                          src="flaro-assets/logos/brands/brand-medium.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full md:w-1/3 p-3.5">
                <a href="/gallery">
                  <div class="relative p-6 h-full hover:text-white hover:bg-gradient-to-br from-[#333333] to-[#e1eec3] border hover:border-amber-500 rounded-xl">
                    <img
                      class="absolute left-0 top-0"
                      src="flaro-assets/images/career/gradient.svg"
                      alt=""
                    />
                    <div class="relative z-10 flex flex-col justify-between h-full">
                      <div class="mb-10 flex-1">
                        <h3 class="mb-2 text-xl font-semibold font-heading leading-snug text-[#FFEFBA]">
                          All Types Of Decoration Events
                        </h3>
                        <p class="text-sm text-gray-500 font-medium">
                          <hr class="h-1 my-8 bg-gradient-to-r from-white border-0" />
                          <p class="text-xl text-[#FFEFBA]">
                            Find Most beautiful and intimate wedding venues to
                            go with your dreams.
                          </p>
                        </p>
                        <button class="bg-orange-500 hover:bg-red-700 text-white font-bold py-2 px-14 ml-12 mt-10 rounded-full">
                          Click for more
                        </button>
                      </div>
                      <div class="flex-1">
                        <img
                          src="flaro-assets/logos/brands/brand-medium.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="w-full md:w-1/3 p-3.5">
                {/*  Social Events.........................................................................................................*/}

                <a href="/gallery">
                  <div class="relative p-6 h-full hover:text-white hover:bg-gradient-to-br from-[#333333] to-[#e1eec3] border hover:border-amber-500 rounded-xl">
                    <img
                      class="absolute left-0 top-0"
                      src="flaro-assets/images/career/gradient.svg"
                      alt=""
                    />
                    <div class="relative z-10 flex flex-col justify-between h-full">
                      <div class="mb-10 flex-1">
                        <h3 class="mb-2 text-xl font-semibold font-heading leading-snug text-[#FFEFBA]">
                          Videography And Photography
                        </h3>
                        <p class="text-sm text-gray-500 font-medium">
                          <hr class="h-1 my-8 bg-gradient-to-r from-white border-0" />
                          <p class="text-xl text-[#FFEFBA]">
                            Explore live events (music, comedy, theatre,
                            art)dining experiences weekend getaway
                          </p>
                        </p>
                        <button class="bg-orange-500 hover:bg-red-700 text-white font-bold py-2 px-14 ml-12 mt-10 rounded-full">
                          Click for more
                        </button>
                      </div>
                      <div class="flex-1">
                        <img
                          src="flaro-assets/logos/brands/brand-medium.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  
                  
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ....................................................................................................................................... */}
    </div>
  )
}
