import React from "react"
import Guru from "../images/Guru2.jpg"
import Doresh from "../images/Doresh.jpg"
import Shruthi from "../images/Shruthi.jpg"

export default function Whatwedo() {
  return (
    <section class="py-14 md:mx-60" style={{ fontFamily: "poppins" }}>
      <div class="text-center text-4xl mb-16">
        <h1>Our Popular Events</h1>
      </div>
      <div class="container px-4 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 mb-16">
          <div class="w-full lg:w-2/5 px-4">
            <div class="text-left">
              {/* <span class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold">Development</span> */}
              <h2 class="mb-4 text-3xl font-semibold font-heading">
                Birthday Party Events
              </h2>
              {/* <span class="inline-block mb-6 text-xs text-gray-500">10 jun 2020 19:40</span> */}
              <p class="text-xl text-gray-500">
                There are very few days as joyful, celebratory, and communal as
                a birthday. From unique birthday party themes to elaborate
                birthday gifts, there's no shortage of ways to celebrate the day
                a family, friend, or loved one was born.
              </p>
            </div>
          </div>
          <div class="order-first lg:order-last w-full lg:w-3/5 px-4 mb-8 lg:mb-0">
            <div class="h-96">
              <img
                class="w-full h-full object-cover rounded-lg"
                src={Guru}
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center -mx-4 mb-16">
          <div class="w-full lg:w-3/5 px-4 mb-8 lg:mb-0">
            <div class="h-96">
              <img
                class="w-full h-full object-cover rounded-lg"
                src={Doresh}
                alt=""
              />
            </div>
          </div>
          <div class="w-full lg:w-2/5 px-4">
            <div class="lg:pl-10 text-left">
              {/* <span class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold">Development</span> */}
              <h2 class="mb-4 text-3xl font-semibold font-heading">
                All Types Of Decoration Events
              </h2>
              {/* <span class="inline-block mb-6 text-xs text-gray-500">10 jun 2020 19:40</span> */}
              <p class="text-xl text-gray-500">
                MAKE MY EVENT is a professional birthday party decorator in{" "}
                <span class="italic text-red-600 text-2xl">Bangalore</span>{" "}
                offering complete event planning services for a birthday. Our
                team of professionals will analyze your requirements and more.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap items-center -mx-4 mb-16">
          <div class="w-full lg:w-2/5 px-4">
            <div class="text-left">
              {/* <span class="inline-block mb-3 text-xs px-2 py-1 bg-blue-50 rounded uppercase text-blue-400 font-semibold">Development</span> */}
              <h2 class="mb-4 text-3xl font-semibold font-heading">
                Video And Photography
              </h2>
              {/* <span class="inline-block mb-6 text-xs text-gray-500">10 jun 2020 19:40</span> */}
              <p class="text-xl text-gray-500">
                Get all the details you need about the event. Set up a time to
                talk with us so you can get a picture of exactly what the event
                will look like.
              </p>
            </div>
          </div>
          <div class="order-first lg:order-last w-full lg:w-3/5 px-4 mb-8 lg:mb-0">
            <div class="h-96">
              <img
                class="w-full h-full object-cover rounded-lg"
                src={Shruthi}
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="text-center">
          <a
            class="px-8 py-4 text-sm text-white font-semibold bg-red-400 hover:bg-red-300 rounded transition duration-200"
            href="/gallery"
          >
            View More Articles
          </a>
        </div>
      </div>
    </section>
  )
}
