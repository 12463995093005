import React from "react"
import Manasa from "../images/Manasabgr.png"
import ReactPlayer from "react-player"

export default function Why() {
  return (
    <div>
      <section class=" overflow-hidden" style={{ fontFamily: "poppins" }}>
        <div class="text-3xl md:text-5xl text-center text-black py-5 md:py-10 font-semibold">
          Why Choose Us
        </div>

        <div class="md:container md:px-4 md:py-10">
          <div class="flex flex-wrap xl:items-center -m-8 md:ml-20 mx-3">
            <div class="md:w-1/2 mt-10 md:mt-0">
              <img
                class="transform hover:translate-x-16 transition ease-in-out duration-1000 "
                src={Manasa}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 md:p-8">
              <div class="md:max-w-md">
                <div class="flex flex-wrap -m-4">
                  <div class="w-auto p-4">
                    <div class="flex flex-wrap -m-2">
                      <div class="flex-1 p-2">
                        <h3 class="text-lg font-semibold text-black mt-5 md:mt-0">
                          We are accommodating
                        </h3>
                      </div>
                      <div class="w-full p-2">
                        <div class="pb-8 border-b">
                          <p class="font-medium leading-relaxed text-black">
                            We understand the passion our clients have, towards
                            their events. We love every new challenge and
                            accommodate even your last minute changes to make
                            your event a grand success within the given budget!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-auto p-4">
                    <div class="flex flex-wrap -m-2">
                      <div class="flex-1 p-2">
                        <h3 class="text-lg font-semibold text-black">
                          We deliver only the best
                        </h3>
                      </div>
                      <div class="w-full p-2">
                        <div class="pb-8 border-b">
                          <p class="font-medium leading-relaxed text-black">
                            We ain’t all talk and no show. Rather, we love to
                            put on a show for you and make the events talk for
                            you! We thrive on listening to happy customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-auto p-4">
                    <div class="flex flex-wrap -m-2">
                      <div class="flex-1 p-2">
                        <h3 class="text-lg font-semibold text-black">
                          We are good listeners
                        </h3>
                      </div>
                      <div class="w-full p-2">
                        <p class="text-black font-medium leading-relaxed">
                          We understand the passion our clients have towards
                          their events. We love every new challenge to make our
                          client’s unique dream a reality.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-gradient-to-br from-[#562334] via-[#39394e] to-[#143e44] md:flex text-[#FFEFBA]"
        style={{ fontFamily: "poppins" }}
      >
        <div class="md:flex justify-center relative md:mx-auto md:w-1/2 mt-10 md:mb-40 md:top-20">
          <p className="absolute text-center text-xl md:text-3xl font-Montserrat font-bold mx-5 md:mx-4 mt-5">
            Our Special Services-Virtual Events
          </p>
          <p class="md:mt-6 md:mx-20 md:mr-4 text-center pt-16">
            Virtual Events are the coolest invention of 2020 that happened
            because of the Corona outbreak across the globe.
          </p>
        </div>

        {/* vedio */}

        <div className="flex items-center mx-2 justify-center">
          <ReactPlayer
            class="px-6 py-16 md:py-5 md:px-5 rounded-xl"
            url="https://www.youtube.com/watch?v=1pJWlLScEo4"
          />
        </div>
      </section>
    </div>
  )
}
